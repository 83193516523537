import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {Grid, Row, Col, Glyphicon} from 'react-bootstrap'
import logoLetteringLight from '../../assets/images/livello9-logo-lettering-light.svg'
import logoLetteringDark from '../../assets/images/livello9-logo-lettering-dark.svg'
import { getFilteredPeriodsWithRelations, getPeriods } from '../../selectors/periods'
import { periodsSetYear } from '../../actions/periods'
import { fetchPeriods } from '../../actions/periods'
import { fetchCategories } from '../../actions/categories'
import { getLoadCategoriesOptionsByPathname } from '../../actions/categories'

import { parseQueryString } from '../../helpers'

import { Body } from './Body'

const mapStateToProps = (state, ownProps) => {
  const [, activeCategory, activePage] = ownProps.location.pathname.split('/')
  const { year, fetching } = getPeriods(state)

  const { preview } = parseQueryString(ownProps.location.search)

  return ({
    periods: getFilteredPeriodsWithRelations({ ...state, preview }),
    filteredYear: year,
    fetchingPeriods: fetching,
    error: state.categories.error,
    fetching: state.categories.fetching,
    activeCategory,
    activePage
  })
}

const mapDispatchToProps = {
  periodsSetYear,
  fetchPeriods,
  fetchCategories
}

class SideMenu extends Component {
  constructor(props){
    super(props)

    this._resetYear = this._resetYear.bind(this)
  }

  _resetYear(){
    this.props.periodsSetYear(null)
    this.props.toggleMenu()
  }

  componentDidMount(){
    const { fetchCategories, fetchPeriods, location } = this.props

    fetchPeriods()

    fetchCategories(getLoadCategoriesOptionsByPathname(location.pathname))
  }

  _filterAndOrderPeriods = () => {
  	const { periods } = this.props

		const periodsOrder = [5,6,11,14,15,7,8,9,10,13,16]

		return 	periodsOrder.reduce((accumulator, periodId) => {
			const period = periods.find(period => period.id === periodId)

			if (period) {
				return [
					...accumulator,
					period
				]
			}

			return accumulator
		}, []);
	}

  render () {
    const {activeCategory, show, activePage, fetchingPeriods, filteredYear} = this.props

    if (!show) return null

    return (
      <nav className="main-menu">
        <Grid fluid>
          <Row style={{marginBottom: '2em'}}>
            <Col style={{marginBottom: '1em'}}>
              <Link to={'/'} onClick={this._resetYear}>
                <img className="logo-light img-responsive" src={logoLetteringLight} alt="Logo Aziendale"/>
                <img className="logo-dark img-responsive" itemProp="logo" src={logoLetteringDark} alt="Logo Aziendale"/>
              </Link>
            </Col>

            <Col>
              <div className="gtranslate_wrapper"></div>
            </Col>
          </Row>

          {filteredYear && (
            <Row
              style={{color: 'white', fontSize: 20, marginBottom: 10, cursor: 'pointer'}}
              onClick={this._resetYear}>
              <Glyphicon
                glyph={'menu-left'}
                style={{margin: '1px 10px 0 0', fontSize: 18}}
              />
              {filteredYear}
            </Row>
          )}

          <Body periods={this._filterAndOrderPeriods()} activePage={activePage} activeCategory={activeCategory}
                handleClick={this._resetYear} loading={fetchingPeriods}/>
        </Grid>
      </nav>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu))
